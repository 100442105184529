const ACTIONS = {
    JOIN: "joinCall",
    LEAVE: "leave",
    SHARE_ROOMS: "share_rooms",
    ADD_PEER: "add_peer",
    REMOVE_PEER: "remove_peer",
    RELAY_SDP: "relay_sdp",
    RELAY_ICE: "relay_ice",
    ICE_CANDIDATE: "ice-candidate",
    SESSION_DESCRIPTION: "session_description",
};

module.exports = ACTIONS;
