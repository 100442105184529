export default function UserIcon(props) {
    return (
        <svg
            {...props}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="12" cy="6" r="4" fill="currentColor" />
            <ellipse cx="12" cy="17" rx="7" ry="4" fill="currentColor" />
        </svg>
    );
}
